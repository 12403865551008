import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import ImageHeader from '../components/ImageHeader';
import PageContent from '../components/PageContent';
import { getDescription } from '../lib/helper';

export default ({ data: { sanityActivity: activity } }) => (
  <Layout>
    <SEO
      title={activity.title}
      fluid={activity.mainImage?.asset.fluid}
      description={getDescription(activity._rawExcerpt)}
    />
    <ImageHeader size="medium" fluid={activity.mainImage?.asset.fluid} />
    <PageContent title={activity.title} content={activity._rawBody} />
  </Layout>
);

export const query = graphql`
  query($slug: String!) {
    sanityActivity(slug: { current: { eq: $slug } }) {
      _rawBody
      _rawExcerpt
      title
      mainImage {
        asset {
          fluid(maxWidth: 1024) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
  }
`;
